<template>
      <div class="index container">

        <transition name="fade">
          <router-view @showParent="showParent">
          </router-view>
        </transition>
        
        <div v-if="showParentPage">
          <v-breadcrumbs>
            <v-breadcrumbs-item :exact="true" :to="{ name: 'home', params: {} }"
              >Home</v-breadcrumbs-item
            >
            <v-breadcrumbs-divider>/</v-breadcrumbs-divider>
            <v-breadcrumbs-item
              :exact="true"
              :to="{ name: 'productSpecsElement', params: {} }"
              active
            >
              Product Specs Element
            </v-breadcrumbs-item>
          </v-breadcrumbs>

        <div class="d-flex flex-column flex-lg-row">
          <v-text-field
            dense
            v-model="searchTerms"
            @keyup.enter="search"
            @click:clear="search"
            @click:append="search"
            placeholder="Press enter to begin search"
            append-icon="mdi-magnify"
            outlined
            clearable
            label="Search"
          />
          <v-btn
            class="ml-0 ml-lg-4 mb-2 mb-lg-0 align-self-end align-self-lg-start"
            color="primary"
            :to="{ name: 'productSpecsElementCreate', params: {} }"
          >
            Create Product Specs Element
          </v-btn>
        </div>

        <div class="table-container">
          <v-data-table
            :headers="headers"
            :items="productSpecsElementList"
            :items-per-page="pagination.pageSize"
            :loading="!productSpecsElementProcessed"
            :server-items-length="pagination.totalSize"
            :page="pagination.current"
            :disable-sort="true"
            item-key="id"
            class="elevation-1"
            @update:options="pageChanged"
          >
            
            <template v-slot:item.actions="{ item }">
              <v-icon small class="mr-2" @click="edit(item)">
                mdi-pencil
              </v-icon>
              <v-icon small class="mr-2" @click="remove(item)">
                mdi-delete
              </v-icon>
            </template>
          </v-data-table>
        </div>
      </div>
      <confirmation-dialog ref="confirm" />
    </div>
  </template>

    <script>
    import { mapActions, mapGetters } from "vuex";
  
    export default {
      data(){
        return {
          pagination: {
            current: 1,
            pageSize: 10,
            totalSize: 100
          },
          productSpecsElementList: [],
          showParentPage: true,
          
      headers: [
        {
          text: "Min Percent",
          value: "minPercent",
          align: "start",
          sortable: true
        },
        {
          text: "Max Percent",
          value: "maxPercent",
          align: "start",
          sortable: true
        },
        
        { text: "Actions", value: "actions", align: "end", sortable: false }
      ],
          sort: {
            key: null
          },
          searchTerms : ''
        }
      },
      computed: {
        ...mapGetters("productSpecsElement", ["productSpecsElementProcessed", "getProductSpecsElements"]),
      },
      methods: {
        ...mapActions("messages", [
          "addErrorMessage",
          "addMessage",
          "addSuccessMessage"
        ]),
        ...mapActions("productSpecsElement", ["deleteProductSpecsElement", "fetchProductSpecsElements"]),
        view(item){
          this.$router.push({ name: 'productSpecsElementView', params: { id: item.id }})
        },
        edit(item){
          this.$router.push({ name: "productSpecsElementEdit", params: { id: item.id }})
        },
        async remove(item){
          let confirm = await this.$refs.confirm.open(
            "Confirm Delete",
            "Do you want to delete this productSpecsElement?"
          );
          if(confirm){
            this.deleteProductSpecsElement(item.id)
              .then( () => {
                this.addSuccessMessage("productSpecsElement was deleted successfully");
                this.getResources();
              }).catch( () => {
                this.addErrorMessage("Server response with error ");
              });
          }
        },
        getResources() {
          const params = {
            page: this.pagination.pageIndex && this.pagination.pageIndex > 0 ? this.pagination.pageIndex-1:0,
            size: this.pagination.pageSize,
          };
          this.fetchProductSpecsElements(params)
            .then(response => {
              if(response){
                this.pagination.totalSize = response.totalElements;
                this.productSpecsElementList = response.content;
              }
            })
            .catch(error => {
              this.addErrorMessage("Server response with error " + error);
            });
        },
        showParent(show) {
          this.showParentPage = show;
        },
        pageChanged(pagination) {
          this.pagination.pageIndex = pagination.page;
          this.pagination.pageSize = pagination.itemsPerPage;

          this.getResources()
        },
        search(){
          this.getResources();
        }
      },
      watch: {
        'pagination.numberRegisterForPage': function(){
            this.pagination.current = -1;
        },
      },
      beforeRouteUpdate(to, from, next) {
        const toDepth = to.path.split("/").length;
        const fromDepth = from.path.split("/").length;
        this.showParentPage = toDepth < fromDepth;
        if (this.showParentPage) {
          this.getResources();
        }
        next();
      }
    }
    </script>

    